/* eslint-disable import/named */
import Vue from "vue";
import {
  Autocomplete,
  Button,
  Checkbox,
  // Clockpicker,
  Collapse,
  // Colorpicker,
  Datepicker,
  // Datetimepicker,
  Dialog,
  Dropdown,
  Field,
  Icon,
  // Image,
  Input,
  Loading,
  // Menu,
  Message,
  Modal,
  Navbar,
  Notification,
  // Numberinput,
  // Pagination,
  // Progress,
  Radio,
  Rate,
  // Select,
  // Skeleton,
  // Sidebar,
  Slider,
  // Snackbar,
  // Steps,
  // Switch,
  // Table,
  Tabs,
  Tag,
  Taginput,
  // Timepicker,
  Toast,
  Tooltip,
  // Upload,
  ConfigProgrammatic,
} from "buefy";

export default function () {
  Vue.use(Autocomplete);
  Vue.use(Button);
  Vue.use(Checkbox);
  // Vue.use(Clockpicker)
  Vue.use(Collapse);
  // Vue.use(Colorpicker)
  Vue.use(Datepicker);
  // Vue.use(Datetimepicker)
  Vue.use(Dialog);
  Vue.use(Dropdown);
  Vue.use(Field);
  Vue.use(Icon);
  // Vue.use(Image)
  Vue.use(Input);
  Vue.use(Loading);
  // Vue.use(Menu)
  Vue.use(Message);
  Vue.use(Modal);
  Vue.use(Navbar);
  Vue.use(Notification);
  // Vue.use(Numberinput)
  // Vue.use(Pagination)
  // Vue.use(Progress)
  Vue.use(Radio);
  Vue.use(Rate);
  // Vue.use(Select);
  // Vue.use(Skeleton)
  // Vue.use(Sidebar)
  Vue.use(Slider);
  // Vue.use(Snackbar);
  // Vue.use(Steps)
  // Vue.use(Switch)
  // Vue.use(Table)
  Vue.use(Tabs);
  Vue.use(Tag);
  Vue.use(Taginput);
  // Vue.use(Timepicker)
  Vue.use(Toast);
  Vue.use(Tooltip);
  // Vue.use(Upload);

  ConfigProgrammatic.setOptions({
    defaultIconPack: "fas",
    defaultIconComponent: "IconComponent",
  });
}
