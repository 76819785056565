<template>
  <JoszakiAutocomplete
    v-model="miniMarketTeamValue"
    v-bind="$attrs"
    icon="user-friends"
    :items="filteredMiniMarketTeams"
    :formatter="(item) => item.name"
    :placeholder="value?.name ?? '-'"
    :loading="loading"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @select="onSelect"
    @remove="onRemove"
  >
    <template #empty>
      {{ $t("conductor.common.noResults") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import { useMiniMarketTeamActions } from "~/components/_refactored/conductor/mini-market-teams/teams/useMiniMarketTeamActions";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { getMiniMarketTeams } = useMiniMarketTeamActions();
    const filteredMiniMarketTeams = ref([]);
    const miniMarketTeamFilter = ref("");
    const miniMarketTeamValue = ref(props.value?.name ?? "-");
    const focused = ref(false);
    const loading = ref(false);

    const onInput = debounce(async function (search) {
      loading.value = true;
      const { items } = await getMiniMarketTeams({
        search,
        page: 1,
        pageSize: 15,
      });
      filteredMiniMarketTeams.value = items;
      loading.value = false;
    }, 500);

    return {
      filteredMiniMarketTeams,
      miniMarketTeamFilter,
      miniMarketTeamValue,
      onInput,
      focused,
      loading,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.miniMarketTeamValue = newValue.name;
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.miniMarketTeamValue = this.miniMarketTeamFilter;
      this.onInput(this.miniMarketTeamFilter);
      this.$emit("focus");
    },
    onBlur() {
      this.focused = false;
      this.miniMarketTeamFilter = this.miniMarketTeamValue;
      this.miniMarketTeamValue = this.value?.name ?? "-";
      this.$emit("blur");
    },
    onSelect(team) {
      this.miniMarketTeamFilter = "";
      if (team) {
        this.$emit("select", team);
      } else {
        this.onBlur();
      }
    },
    onRemove(team) {
      this.miniMarketTeamFilter = "";
      if (team) {
        this.$emit("remove", team);
      } else {
        this.onBlur();
      }
    },
  },
};
</script>
